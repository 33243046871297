import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store

/**
 * API for interacting with order-related endpoints.
 */
const OrderAPI = {
  /**
   * Gets all orders for a specific user.
   *
   * @param {Object} params - Parameters for the request.
   * @param {boolean} [params.isActive=true] - Get all active orders.
   * @param {string} [params.status=""] - Status text filter if any.
   * @param {string} [params.pickupPoint=""] - Pickup point filter.
   * @param {string} [params.pickupTime=""] - Pickup time filter.
   * @param {number} [params.secondsOld=-1] - Seconds old filter, e.g. 10 minutes = 600, get orders no older than this.
   * @param {number} [params.limit=10] - Limit per page.
   * @param {number} [params.page=1] - Page (1-indexed).
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the orders.
   */
  getUserOrders: ({
    status = "",
    pickupPoint = "",
    pickupTime = "",
    secondsOld = -1,
    limit = 100,
    page = 1,
    userId = "",
  } = {}) => {
    const storeId = store.state.storeId || ""; // Fetch storeId from Vuex store
    // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    return axiosInstance.get(`/order/user/${userId}`, {
      headers: {
        storeId,
      },
      params: {
        status,
        pickupPoint,
        pickupTime,
        secondsOld,
        limit,
        page,
      },
    });
  },
  getOrder: ({ orderId = "" } = {}) => {
    return axiosInstance.get(`/order/${orderId}`);
  },

  collectOrder: (orderId) => {
    return axiosInstance.post(`order/collect/${orderId}`);
  },
};

export default OrderAPI;
