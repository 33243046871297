<template>
  <v-container fluid>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular> </v-row
    ><v-col v-else>
      <v-form ref="createRecipe">
        <p class="bold-h1">
          {{ create ? $t("create_recipe") : $t("change_recipe") }}
        </p>
        <v-row class="mt-4">
          <v-col cols="12" :md="mainContentWidth < 800 ? '12' : '6'">
            <ImageInput
              :image="recipe.image"
              ref="imageInput"
              type="banner"
              :ratio="2 / 2"
              :edit="true"
            />
            <v-col
              ><p class="bold-h2">{{ $t("ingredients") }}</p>

              <v-col
                v-for="(group, index) in groups.filter(
                  (g) => g.value != 'new_item'
                )"
                :key="group"
                class="pa-0"
              >
                <v-row class="pa-3" justify="space-between" align="center">
                  <v-row class="pa-3" align="end">
                    <p class="bold-h2" v-if="group.value == ''">
                      {{ group.value }}
                    </p>
                    <v-text-field
                      v-else
                      v-model.number="group.value"
                      color="background"
                      bg-color="background"
                      variant="underlined"
                      :rules="textFieldRules"
                      validate-on="submit"
                      single-line
                      hide-details
                      class="big-text"
                      @update:modelValue="updatingGroupValue(group)"
                    ></v-text-field>
                  </v-row>

                  <v-btn
                    v-if="group.value != ''"
                    variant="plain"
                    icon="mdi-delete"
                    @click="showDeleteGroupDialog(group)"
                  ></v-btn
                ></v-row>

                <v-row
                  class="pa-3"
                  v-for="ingredient in groupedIngredients.filter(
                    (e) => e.group == group.value
                  )"
                  ><p class="body-2">
                    {{
                      parseFloat(ingredient.weight) +
                      " " +
                      ingredient.weightUnit
                    }}
                  </p>
                  <p class="bold-h4 ml-2">
                    {{
                      ingredient.product != null
                        ? ingredient.product.name
                        : ingredient.name
                    }}
                  </p></v-row
                >
              </v-col>
              <v-row align="start" justify="center" class="mt-4 pa-4">
                <v-text-field
                  v-model.number="subgroup"
                  :label="$t('subgroup')"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  validate-on="submit"
                  single-line
                ></v-text-field>
                <v-btn
                  @click="addSubGroup"
                  class="ml-4 button text-none"
                  color="primary"
                  elevation="0"
                  >{{ $t("add_subgroup") }}</v-btn
                >
              </v-row>
            </v-col>
            <v-col>
              <p class="bold-h2">{{ $t("instructions") }}</p>
              <draggable v-model="recipe.instructions" @change="orderChanged">
                <v-card
                  class="pa-4 mt-2 hover"
                  v-for="(element, index) in recipe.instructions"
                  :key="element.instruction"
                >
                  <v-row class="pa-2" align="center">
                    <v-col cols="1">
                      <v-avatar color="primary">{{
                        element.step
                      }}</v-avatar></v-col
                    >

                    <v-col cols="8">
                      <p class="body-2 ml-4">
                        {{ element.instruction }}
                      </p></v-col
                    >

                    <v-col cols="2" justifty="end" align="end">
                      <v-btn
                        variant="plain"
                        icon="mdi-delete"
                        @click="recipe.instructions.splice(index, 1)"
                      ></v-btn
                    ></v-col>
                    <v-col cols="1" justifty="end" align="end">
                      <v-icon icon="mdi-menu"
                    /></v-col>
                  </v-row>
                </v-card>
              </draggable>
              <v-form ref="addStep">
                <v-row class="pa-3 mt-4" justify="center">
                  <v-textarea
                    v-model="newInstruction"
                    :label="$t('step')"
                    rows="2"
                    color="primary"
                    bg-color="surface"
                    variant="outlined"
                    single-line
                    :rules="textFieldRules"
                    validate-on="submit"
                    class="body-1 mb-2"
                  ></v-textarea>
                  <v-col align="center" class="pa-0 ml-4"
                    ><v-btn
                      flat
                      color="primary"
                      @click="addStep"
                      class="button text-none"
                      >{{ $t("add_step") }}</v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>
            </v-col>
          </v-col>
          <v-col cols="12" :md="mainContentWidth < 800 ? '12' : '6'">
            <v-card elevation="0"
              ><v-col>
                <p class="bold-h4">{{ $t("name") }}</p>
                <v-text-field
                  v-model="recipe.name"
                  :label="$t('name')"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  :rules="textFieldRules"
                  validate-on="submit"
                  single-line
                  class="body-1 mt-2"
                ></v-text-field>

                <p class="bold-h4">{{ $t("description") }}</p>
                <v-textarea
                  v-model="recipe.description"
                  :label="$t('description')"
                  rows="2"
                  color="primary"
                  bg-color="surface"
                  :rules="textFieldRules"
                  validate-on="submit"
                  variant="outlined"
                  single-line
                  class="body-1 mt-2"
                ></v-textarea>

                <v-row>
                  <v-col>
                    <p class="bold-h4">{{ $t("time") }}</p>
                    <v-text-field
                      v-model.number="recipe.time"
                      :label="$t('time')"
                      color="primary"
                      bg-color="surface"
                      type="number"
                      variant="outlined"
                      :rules="textFieldRules"
                      validate-on="submit"
                      single-line
                      class="body-1 mt-2"
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <p class="bold-h4">{{ $t("difficulty") }}</p>
                    <v-select
                      :items="['Lätt', 'Medel', 'Svår']"
                      v-model="recipe.difficulty"
                      :label="$t('difficulty')"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      :rules="textFieldRules"
                      validate-on="submit"
                      single-line
                      class="body-1 mt-2"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-col>
                  <v-row align="center" justify="space-between">
                    <p class="body-2">{{ $t("share_recipe_prompt") }}</p>
                    <v-col cols="2">
                      <v-switch
                        v-model="recipe.onlineAvailability"
                        hide-details
                        color="primary"
                        inset
                      ></v-switch
                    ></v-col>
                  </v-row>
                </v-col> </v-col
            ></v-card>

            <v-card elevation="0" class="mt-4"
              ><v-col align="center"
                ><p class="bold-h2">
                  {{ totalPrice.toFixed(2) + " kr" }}
                </p>
                <p class="body-2">
                  {{ portionPrice.toFixed(2) + " kr/portion" }}
                </p>
                <v-col>
                  <v-card color="secondary" elevation="0">
                    <v-row
                      class="pa-4"
                      justify="center"
                      align="center"
                      cols="12"
                    >
                      <v-col
                        class="align-stretch pa-0"
                        justify="center"
                        align="center"
                      >
                        <v-icon
                          size="large"
                          color="surface"
                          @click.stop="recipe.portions--"
                          >mdi-minus</v-icon
                        >
                      </v-col>
                      <v-col
                        class="align-stretch pa-0"
                        align="center"
                        justify="center"
                        sm="4"
                      >
                        <p
                          class="body-2"
                          style="color: #f5f5f9; text-overflow: ellipsis"
                        >
                          {{
                            $t("portions_count", {
                              count: recipe.portions,
                            })
                          }}
                        </p>
                      </v-col>
                      <v-col
                        class="align-stretch pa-0"
                        justify="center"
                        align="center"
                      >
                        <v-icon
                          size="large"
                          @click.stop="recipe.portions++"
                          color="surface"
                          >mdi-plus</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col></v-col
              ></v-card
            >
            <p class="bold-h2">{{ $t("products") }}</p>

            <v-autocomplete
              v-model="tag"
              :items="products"
              :loading="loadingProducts"
              v-model:search="searchInput"
              prepend-inner-icon="mdi-magnify"
              menu-icon=""
              :filter="() => true"
              color="primary"
              bg-color="surface"
              variant="outlined"
              single-line
              no-filter
              hide-selected
              hide-no-data
              item-value="_id"
              item-title="name"
              :label="$t('search_item') + '...'"
            >
              <template v-slot:prepend-item v-if="searchInput != ''">
                <v-card class="pa-2 ma-2" elevation="0">
                  <v-row class="pa-2">
                    <v-col
                      align="center"
                      justify="center"
                      cols="2"
                      style="position: relative"
                      class="pa-2"
                    >
                      <v-img
                        :src="template_product"
                        alt="Product Image"
                        class="hover"
                        height="60"
                      />
                    </v-col>
                    <v-col
                      ><p class="bold-1">{{ "Lägg till produkt" }}</p>
                      <p>{{ searchInput }}</p></v-col
                    >
                    <v-col align="end" justify="end"
                      ><v-btn
                        elevation="0"
                        :color="tagIsInList(searchInput) ? 'error' : 'primary'"
                        flat
                        class="text-none button"
                        @click="addSearchProduct(searchInput)"
                        >{{
                          tagIsInList(searchInput) ? $t("remove") : $t("add")
                        }}</v-btn
                      ></v-col
                    ></v-row
                  >
                  <v-divider :thickness="2"></v-divider>
                </v-card>
              </template>
              <template v-slot:item="{ props, item }">
                <v-card :key="item.raw._id" class="pa-2 ma-2" elevation="0">
                  <v-row class="pa-2">
                    <v-col
                      align="center"
                      justify="center"
                      cols="2"
                      style="position: relative"
                      class="pa-2"
                    >
                      <DiscountPrice
                        v-if="item.raw.promotion"
                        style="
                          position: absolute;
                          top: 3px;
                          right: 0px;
                          z-index: 1000;
                        "
                        :size="
                          !$vuetify.display.xs
                            ? { width: 30, height: 30 }
                            : { width: 20, height: 20 }
                        "
                        :promotion="item.raw.promotion"
                        :isWeight="item.raw.weightProduct"
                      />
                      <v-img
                        :src="item.raw.thumbnail.url"
                        alt="Product Image"
                        class="hover"
                        height="60"
                        @click="handleProductClick(item.raw)"
                      />
                    </v-col>
                    <v-col align="space-between" class="pa-2 pr-4 pt-4">
                      <v-row class="pl-3 pr-3 pt-1">
                        <p class="bold-1">
                          {{ item.raw.name }}
                        </p>
                      </v-row>
                      <v-row class="pl-3 pr-3 mt-1" align="end">
                        <v-col class="pa-0" justify="center">
                          <v-col sm="12" class="pa-0 pr-1">
                            <p class="body-3">
                              {{
                                item.raw.manufacturer +
                                " " +
                                item.raw.displayWeight
                              }}
                            </p></v-col
                          ></v-col
                        >
                        <v-col
                          class="pa-0 pl-1 pt-2"
                          cols="5"
                          sm="4"
                          align="end"
                        >
                        </v-col>
                      </v-row>

                      <p
                        class="bold-h3 mt-4"
                        style="color: #c94631"
                        v-if="
                          item.raw.promotion != null &&
                          item.raw.promotion.minQuantity == 1
                        "
                      >
                        <span>
                          {{
                            item.raw.promotion.discountValue.toFixed(2)
                          }}</span
                        >
                        {{
                          "/" +
                          (item.raw.weightProduct
                            ? "kg"
                            : $t("piece_abbreviation"))
                        }}
                      </p>

                      <p class="bold-h4 mt-4" v-else>
                        <span> {{ item.raw.price }}</span>
                        {{
                          "/" +
                          (item.raw.weightProduct
                            ? "kg"
                            : $t("piece_abbreviation"))
                        }}
                      </p>
                    </v-col>
                    <v-col align="end" justify="end">
                      <v-btn
                        :color="
                          productIsInList(item.raw._id) ? 'error' : 'primary'
                        "
                        flat
                        class="text-none button"
                        @click="addRemoveProduct(item.raw)"
                        >{{
                          productIsInList(item.raw._id)
                            ? $t("remove")
                            : $t("add")
                        }}</v-btn
                      >
                    </v-col>
                    <v-divider :thickness="2"></v-divider>
                  </v-row>
                </v-card>
              </template>
            </v-autocomplete>
            <v-card
              v-for="(ingredient, index) in recipe.ingredients"
              :key="index"
              color="surface"
              class="mb-2"
              elevation="0"
            >
              <v-row align="center" justify="center" class="pa-4">
                <v-col>
                  <v-row
                    align="center"
                    justify="center"
                    v-if="ingredient.product"
                  >
                    <v-col
                      align="center"
                      justify="center"
                      cols="3"
                      style="position: relative"
                    >
                      <DiscountPrice
                        v-if="ingredient.product.promotion"
                        style="
                          position: absolute;
                          top: 3px;
                          right: 0px;
                          z-index: 1000;
                        "
                        :size="{ width: 45, height: 45 }"
                        :promotion="ingredient.product.promotion"
                        :isWeight="ingredient.product.weightProduct"
                      />
                      <v-img
                        :src="ingredient.product.thumbnail.url"
                        alt="Product Image"
                        class="hover"
                        height="100"
                        @click="handleProductClick(ingredient.product)"
                      />
                    </v-col>
                    <v-col align="space-between">
                      <v-row class="pa-2 pt-1">
                        <v-col class="pa-0">
                          <v-col sm="12" class="pa-0">
                            <p class="bold-1">
                              {{
                                Math.ceil(
                                  recipe.portions * ingredient.portionQuantity
                                ) +
                                ". " +
                                ingredient.product.name
                              }}
                            </p></v-col
                          >
                        </v-col>
                      </v-row>

                      <v-row class="pa-2 mt-0" align="center">
                        <v-col class="pa-0">
                          <p class="body-4">
                            {{
                              ingredient.product.manufacturer +
                              " " +
                              ingredient.product.displayWeight
                            }}
                          </p>
                          <p class="bold-1">
                            {{
                              (ingredient.product.price != undefined
                                ? ingredient.product.weightProduct &&
                                  ingredient.product.weightInKg != null
                                  ? (
                                      ingredient.product.price *
                                      ingredient.product.weightInKg *
                                      Math.ceil(
                                        recipe.portions *
                                          ingredient.portionQuantity
                                      )
                                    ).toFixed(2)
                                  : (
                                      ingredient.product.price *
                                      Math.ceil(
                                        recipe.portions *
                                          ingredient.portionQuantity
                                      )
                                    ).toFixed(2)
                                : "") + " kr"
                            }}
                          </p>
                        </v-col>
                        <v-col class="pa-0">
                          <v-switch
                            v-model="ingredient.isBasic"
                            hide-details
                            color="primary"
                            :label="$t('basic_ingredient')"
                          ></v-switch
                        ></v-col>
                        <v-col
                          class="pa-2"
                          cols="2"
                          justify="center"
                          align="center"
                          ><v-btn
                            variant="plain"
                            icon="mdi-delete"
                            @click="addRemoveProduct(ingredient.product)"
                          ></v-btn></v-col
                      ></v-row>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center" v-else>
                    <v-col
                      align="center"
                      justify="center"
                      cols="3"
                      style="position: relative"
                    >
                      <v-img
                        :src="template_product"
                        alt="Product Image"
                        class="hover"
                        height="100"
                      />
                    </v-col>
                    <v-col align="space-between">
                      <v-row class="pa-2 pt-1">
                        <v-col class="pa-0">
                          <v-col sm="12" class="pa-0">
                            <p class="bold-h4">
                              {{ ingredient.name }}
                            </p></v-col
                          >
                        </v-col>
                        <v-col
                          class="pa-2"
                          cols="2"
                          justify="center"
                          align="center"
                          ><v-btn
                            variant="plain"
                            icon="mdi-delete"
                            @click="addSearchProduct(ingredient.name)"
                          ></v-btn
                        ></v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row
                    class="pl-2 pr-3 mt-0"
                    align="center"
                    v-for="(recipeGroup, index) in ingredient.groups"
                  >
                    <v-col class="pa-0" cols="3">
                      <v-select
                        :items="groups"
                        v-model="recipeGroup.group"
                        item-title="abbr"
                        item-value="value"
                        :label="$t('group')"
                        color="primary"
                        bg-color="surface"
                        variant="outlined"
                        single-line
                        class="body-1 mt-2"
                        validate-on="submit"
                        @update:modelValue="changeGroup(recipeGroup)"
                      ></v-select
                    ></v-col>
                    <v-col class="pa-0 ml-3" cols="4">
                      <v-text-field
                        v-model.number="recipeGroup.weight"
                        v-sanitize-number="recipeGroup.weight"
                        :rules="numberTextFieldRules"
                        validate-on="submit"
                        :label="$t('quantity')"
                        color="primary"
                        bg-color="surface"
                        variant="outlined"
                        single-line
                        class="body-1 mt-2"
                        @change="changeIngredient(ingredient)"
                      ></v-text-field
                    ></v-col>
                    <v-col>
                      <v-select
                        :items="weightUnits"
                        item-title="abbr"
                        item-value="abbr"
                        v-model="recipeGroup.weightUnit"
                        :label="$t('measurement')"
                        color="primary"
                        bg-color="surface"
                        variant="outlined"
                        single-line
                        class="body-1 mt-2"
                        :rules="textFieldRules"
                        validate-on="submit"
                        @update:modelValue="changeIngredient(ingredient)"
                      ></v-select
                    ></v-col>
                    <v-btn
                      variant="plain"
                      icon="mdi-delete"
                      @click="removeReceipeGroup(ingredient, index)"
                    ></v-btn>
                  </v-row>
                  <v-btn
                    @click="addReceipeGroup(ingredient)"
                    elevation="0"
                    class="button text-none"
                    color="primary"
                    >{{ $t("add_more") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>

            <v-btn
              class="mt-4 button-xl text-none"
              size="x-large"
              flat
              block
              color="success"
              :disabled="createRecipeLoading"
              :loading="createRecipeLoading"
              @click="createRecipe"
              >{{ create ? $t("create_recipe") : $t("change_recipe") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col></v-container
  >
  <v-dialog
    max-width="500"
    v-model="new_subGroup_dialog"
    @click:outside="declineSubGroup"
  >
    <v-card
      ><v-col>
        <p class="bold-h3">{{ $t("new_subgroup") }}</p>
        <v-text-field
          v-model.number="subgroup"
          :label="$t('subgroup')"
          color="primary"
          bg-color="surface"
          variant="outlined"
          :rules="textFieldRules"
          validate-on="submit"
          single-line
          class="body-1 mt-4"
        ></v-text-field>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="declineSubGroup"
          color="error"
          variant="flat"
          class="button text-none"
        >
          {{ $t("close") }}</v-btn
        >
        <v-btn
          @click="addSubGroupDialog"
          color="primary"
          variant="flat"
          class="button text-none"
        >
          {{ $t("add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="delete_subGroup_dialog"
    @click:outside="delete_subGroup_dialog = false"
  >
    <v-card
      ><v-col>
        <p class="bold-h3">{{ $t("delete_subgroup") }}</p>
        <p>{{ $t("are_you_sure_delete_subgroup") }}</p>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="delete_subGroup_dialog = false"
          color="primary"
          variant="flat"
          class="button text-none"
        >
          {{ $t("close") }}</v-btn
        >
        <v-btn
          @click="deleteRecipeGroup()"
          color="error"
          variant="flat"
          class="button text-none"
        >
          {{ $t("delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { useHead } from "@unhead/vue";
import imageInput from "@/components/imageInput.vue";
import { VueDraggableNext } from "vue-draggable-next";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import RecipeAPI from "@/api/RecipeAPI";
import { mapState, mapActions, mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import template_product from "../assets/images/template-banner.png";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "Create Recipe",
  components: {
    imageInput,
    draggable: VueDraggableNext,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      loading: true,
      create: true,
      recipe: {
        name: "",
        description: "",

        onlineAvailability: false,
        instructions: [],
        ingredients: [],
        portions: 4,
      },
      template_product: template_product,
      subgroup: "",
      groups: [
        {
          abbr: "Alla",
          value: "",
        },
        {
          abbr: "Lägg till ny",
          value: "new_item",
        },
      ],
      weightUnits: [
        {
          abbr: "st",
          description: "st",
          to_kilogram: 1,
        },
        {
          abbr: "klyfta",
          description: "klyfta",
          to_kilogram: 0.1,
        },
        {
          abbr: "g",
          description: "gram",
          to_kilogram: 0.001,
        },
        {
          abbr: "kg",
          description: "kilogram",
          to_kilogram: 1,
        },
        {
          abbr: "dl",
          description: "deciliter",
          to_kilogram: 0.1,
        },
        {
          abbr: "msk",
          description: "matsked",
          to_kilogram: 0.015,
        },
        {
          abbr: "tsk",
          description: "tesked",
          to_kilogram: 0.005,
        },
        {
          abbr: "krm",
          description: "kryddmått",
          to_kilogram: 0.001,
        },
        {
          abbr: "l",
          description: "liter",
          to_kilogram: 1,
        },
      ],
      createRecipeLoading: false,
      newInstruction: "",
      products: [],
      loadingProducts: false,
      new_subGroup_dialog: false,
      delete_subGroup_dialog: false,
      selectedGroup: null,
      searchInput: null,
      tag: null,
      debounceTimer: null,
      selectedRecipeGroup: null,
      debounceDelay: 700,
      textFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
      ],
      numberTextFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
        (value) => {
          if (!isNaN(value)) return true;
          return this.t("must_be_number");
        },
      ],
    };
  },
  watch: {
    searchInput(val) {
      this.products = [];
      if (val != undefined && val != "") {
        this.loadingProducts = true;
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(async () => {
          this.fetchProducts(val);
        }, this.debounceDelay);
      } else {
        clearTimeout(this.debounceTimer);
      }
    },
  },
  computed: {
    ...mapState(["user", "mainContentWidth"]),
    tagsInRecipe() {
      return this.recipe.ingredients.filter((e) => e.name != null);
    },
    productsInRecipe() {
      return this.recipe.ingredients.filter((e) => e.product != null);
    },
    groupedIngredients() {
      const t = this.recipe.ingredients.reduce(
        (acc, { product, name, groups }) => {
          groups.forEach((recipeGroup) => {
            acc.push({
              group: recipeGroup.group, // Make sure you're using the correct property
              product: product,
              name: name,
              weight: recipeGroup.weight,
              weightUnit: recipeGroup.weightUnit,
            });
          });
          return acc;
        },
        []
      );

      console.log("groupedIngredients", t);

      return t;
    },
    totalPrice() {
      return this.productsInRecipe.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.product.weightProduct &&
          currentValue.product.weightInKg != null
            ? currentValue.product.price * currentValue.product.weightInKg
            : currentValue.product.price) *
            Math.ceil(this.recipe.portions * currentValue.portionQuantity),
        0
      );
    },
    portionPrice() {
      return this.productsInRecipe.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.product.weightProduct &&
          currentValue.product.weightInKg != null
            ? currentValue.product.price * currentValue.product.weightInKg
            : currentValue.product.price) *
            currentValue.portionQuantity,
        0
      );
    },
  },
  methods: {
    updatingGroupValue(group) {
      console.log("UPDATEGROUPVALUE", group);
      for (let index = 0; index < this.recipe.ingredients.length; index++) {
        console.log("GROUPS", this.recipe.ingredients[index]);
        for (
          let r_index = 0;
          r_index < this.recipe.ingredients[index].groups.length;
          r_index++
        ) {
          console.log(
            "GROUP",
            this.recipe.ingredients[index].groups[r_index].group
          );
          if (
            group.abbr == this.recipe.ingredients[index].groups[r_index].group
          ) {
            console.log("DELETE GROUP");
            this.recipe.ingredients[index].groups[r_index].group = group.value;
          }
        }
      }
      group.abbr = group.value;
    },
    showDeleteGroupDialog(group) {
      this.selectedGroup = group;
      this.delete_subGroup_dialog = true;
    },
    deleteRecipeGroup() {
      for (let index = 0; index < this.recipe.ingredients.length; index++) {
        console.log("GROUPS", this.recipe.ingredients[index]);

        for (
          let r_index = 0;
          r_index < this.recipe.ingredients[index].groups.length;
          r_index++
        ) {
          console.log(
            "GROUP",
            this.recipe.ingredients[index].groups[r_index].group
          );
          if (
            this.selectedGroup.value ==
            this.recipe.ingredients[index].groups[r_index].group
          ) {
            console.log("DELETE GROUP");
            this.recipe.ingredients[index].groups.splice(r_index, 1);
          }
        }
      }
      this.recipe.ingredients = this.recipe.ingredients.filter(
        (ingredient) => ingredient.groups.length > 0
      );
      console.log("VALUE", this.selectedGroup.value);
      const group_index = this.groups.findIndex(
        (g) => g.value == this.selectedGroup.value
      );
      console.log("GROUPINDEX", group_index);
      this.groups.splice(group_index, 1);
      console.log("THIS GROUPS", this.groups);
      this.selectedGroup = null;
      this.delete_subGroup_dialog = false;
    },
    declineSubGroup() {
      this.new_subGroup_dialog = false;
      this.selectedRecipeGroup = null;
    },
    changeGroup(v) {
      console.log("CHANGE GROUP", v);
      if (v.group == "new_item") {
        v.group = "";
        this.subgroup = "";
        this.new_subGroup_dialog = true;
        this.selectedRecipeGroup = v;
      }
    },

    getFullName(product) {
      return product._id != null ? product._id : "test";
    },
    addSubGroupDialog() {
      this.groups.splice(this.groups.length - 1, 0, {
        abbr: this.subgroup,
        value: this.subgroup,
      });

      this.selectedRecipeGroup.group = this.subgroup;
      this.subgroup = "";
      this.selectedRecipeGroup = null;
      this.new_subGroup_dialog = false;
    },
    addSubGroup() {
      if (
        this.subgroup != "" &&
        this.groups.every((e) => e.value != this.subgroup)
      ) {
        this.groups.splice(this.groups.length - 1, 0, {
          abbr: this.subgroup,
          value: this.subgroup,
        });
        this.subgroup = "";
      }
    },
    addReceipeGroup(ingredient) {
      ingredient.groups.push({ group: "", weight: 0, weightUnit: "st" });
    },
    removeReceipeGroup(ingredient, index) {
      ingredient.groups.splice(index, 1);
    },
    changeIngredient(ingredient) {
      ingredient.portionQuantity = 0;
      console.log("INGREDIENT", ingredient);
      if (ingredient.product != null) {
        for (const recipeGroup of ingredient.groups) {
          if (recipeGroup.weight && recipeGroup.weightUnit) {
            const weight = parseFloat(recipeGroup.weight);
            if (
              recipeGroup.weightUnit == "st" ||
              recipeGroup.weightUnit == "klyfta"
            ) {
              const weightUnit = this.weightUnits.find(
                (e) => e.abbr == recipeGroup.weightUnit
              );
              const totalWeight = weight * weightUnit.to_kilogram;
              const quantity = this.extractQuantity(
                ingredient.product.displayWeight
              );
              if (quantity) {
                ingredient.portionQuantity +=
                  totalWeight / quantity / this.recipe.portions;
              } else {
                ingredient.portionQuantity +=
                  totalWeight / this.recipe.portions;
              }
            } else {
              const weightUnit = this.weightUnits.find(
                (e) => e.abbr == recipeGroup.weightUnit
              );

              const totalWeight = weight * weightUnit.to_kilogram;
              const productWeight = this.getProductWeight(ingredient.product);

              ingredient.portionQuantity +=
                totalWeight / productWeight / this.recipe.portions;
              recipeGroup.weight = recipeGroup.weight;
            }
          }
        }
      }

      console.log("ingredient", ingredient);
    },
    extractQuantity(displayWeight) {
      // Match for pieces (e.g., "15p" or "15st") or combined formats (e.g., "15p/33cl")
      const piecesMatch = displayWeight.match(
        /^(\d+)([ps])(?:\/\d+[a-zA-Z]*)?$/
      );

      if (piecesMatch) {
        const quantity = parseFloat(piecesMatch[1]); // Get the first numeric value
        const unit = piecesMatch[2]; // Get the unit part (either "p" or "s")

        // Check if the display weight ends with "st" or "p"
        if (unit === "p" || unit === "s") {
          return quantity; // Return the quantity if valid
        }
      }

      return null; // Return null if no valid match found
    },
    getProductWeight(product) {
      const weightMatch = product.displayWeight.match(
        /^(\d+(\/\d+)?)([a-zA-Z]+)$/
      );

      if (!weightMatch) {
        return product.weightInKg;
      }
      // Extract the first value and the unit
      const value = parseFloat(weightMatch[1]); // First value (e.g., "590")
      const unit = weightMatch[3]; // e.g., "g"

      // Convert based on unit
      switch (unit.toLowerCase()) {
        case "g":
          return value / 1000; // grams to kilograms
        case "kg":
          return value; // already in kilograms
        case "l":
          return value; // assuming 1 liter of water = 1 kilogram
        case "dl":
          return value * 0.1;
        case "ml":
          return value * 0.01; // deciliters to kilograms (1 dl = 0.1 kg for water)
        case "msk":
          return value * 0.015; // tablespoons to kilograms (1 ms = 15 g = 0.015 kg for water)
        case "tsk":
          return value * 0.005; // teaspoons to kilograms (1 tsk = 5 g = 0.005 kg for water)
        case "krm":
          return value * 0.001;
        default:
          return product.weightInKg;
      }
    },
    async createRecipe() {
      try {
        const { valid } = await this.$refs.createRecipe.validate();

        if (!valid) return;
        if (this.recipe.instructions.length == 0) {
          this.$store.dispatch("vtoast/showToast", {
            message: "Du måste lägga till en instruktion",
            color: "error",
          });
          return;
        }
        if (this.recipe.ingredients.length == 0) {
          this.$store.dispatch("vtoast/showToast", {
            message: "Du måste lägga till en produkt",
            color: "error",
            timer: 5000,
          });
          return;
        }
        const childComponent = this.$refs.imageInput;
        // Check if the child component and the method exist before calling it

        if (
          childComponent &&
          childComponent.hasImage &&
          !childComponent.hasImage() &&
          this.create
        ) {
          this.$store.dispatch("vtoast/showToast", {
            message: "Du måste lägga till en bild",
            color: "error",
            timer: 5000,
          });
          return;
        }

        this.createRecipeLoading = true;
        this.recipe.userId = this.user._id;
        const recipe = this.recipe;
        const body = {
          name: recipe.name,
          instructions: this.recipe.instructions.map((p) => ({
            instruction: p.instruction,
            step: p.step,
          })),
          userId: this.user._id,
          portions: recipe.portions,
          description: recipe.description,
          difficulty: recipe.difficulty,
          time: recipe.time,
          ingredients: this.recipe.ingredients.map((p) => ({
            portionQuantity: p.portionQuantity,
            ...(p.product
              ? { product: p.product._id }
              : p.name
              ? { name: p.name }
              : {}),
            groups: p.groups.map((g) => ({
              group: g.group,
              weight: g.weight / this.recipe.portions,
              weightUnit: g.weightUnit,
            })),
            isBasic: p.isBasic,
          })),
        };
        console.log("BODY", body);
        //const resp = await RecipeAPI.createRecipe();
        let resp = {};
        if (
          childComponent &&
          childComponent.uploadImage &&
          childComponent.hasImage()
        ) {
          const formData = await childComponent.uploadImage(body);
          resp = this.create
            ? await RecipeAPI.postImage(formData)
            : await RecipeAPI.updateImage(this.recipe._id, formData);
        } else if (!this.create) {
          resp = await RecipeAPI.updateRecipe(this.recipe._id, body);
        }
        this.createRecipeLoading = false;
        this.newInstruction = "";
        this.tag = null;
        this.recipe = {
          name: "",
          description: "",
          onlineAvailability: false,
          instructions: [],
          ingredients: [],
          image: undefined,
          portions: 4,
        };
        if (childComponent && childComponent.setPropImage) {
          await childComponent.setPropImage();
        }
        this.$router.push({
          name: "recipeDetail",
          params: { id: resp.data._id },
        });
      } catch (error) {
        console.log(error);
        this.createRecipeLoading = false;
      }
    },
    tagIsInList(id) {
      return this.recipe.ingredients.findIndex((e) => e.name == id) != -1;
    },
    productIsInList(id) {
      return this.productsInRecipe.findIndex((e) => e.product._id == id) != -1;
    },
    addSearchProduct(tag) {
      console.log("TAG", {
        name: tag,
        product: null,
        isBasic: false,
        portionQuantity: 1 / this.recipe.portions,
        groups: [{ group: "", weight: 0, weightUnit: null }],
      });
      const index = this.tagsInRecipe.findIndex((e) => e.name == tag);
      if (index === -1) {
        this.recipe.ingredients.push({
          name: tag,
          product: null,
          isBasic: false,
          portionQuantity: 1 / this.recipe.portions,
          groups: [{ group: "", weight: 0, weightUnit: "st" }],
        });
      } else {
        this.recipe.ingredients.splice(index, 1);
      }
    },
    addRemoveProduct(product) {
      const index = this.productsInRecipe.findIndex(
        (e) => e.product._id == product._id
      );

      if (index === -1) {
        this.recipe.ingredients.push({
          product: product,
          isBasic: false,
          portionQuantity: 1 / this.recipe.portions,
          groups: [{ group: "", weight: 0, weightUnit: "st" }],
        });
      } else {
        this.recipe.ingredients.splice(index, 1);
      }
    },
    orderChanged(order) {
      for (let index = 0; index < this.recipe.instructions.length; index++) {
        this.recipe.instructions[index].step = index + 1;
      }
    },
    async addStep() {
      const { valid } = await this.$refs.addStep.validate();

      if (!valid) return;
      this.recipe.instructions.push({
        instruction: this.newInstruction,
        step: this.recipe.instructions.length + 1,
      });
      this.newInstruction = "";
    },
    async fetchProducts(search) {
      try {
        this.loadingProducts = true;

        const response = await CategoryProductAPI.getProducts({
          searchString: search,
        });

        this.products = response.data;

        this.loadingProducts = false;
        this.products = response.data;
        this.tag = search;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async fetchRecipe() {
      try {
        this.loading = true;
        const response = await RecipeAPI.fetchRecipe(this.$route.params.id);
        const tempRecipe = response.data;

        if (this.user._id != tempRecipe.userId) {
          this.$router.push({
            name: "createRecipe",
          });
          this.loading = false;
          return;
        }
        this.recipe = tempRecipe;
        console.log("THIS RECIPE", this.recipe);
        this.recipe.ingredients = this.recipe.activeIngredients.map((item) => {
          // Use Array.find to find the store object with storeID "1"
          item.groups.forEach((g) => {
            if (this.groups.findIndex((e) => e.value == g.group) == -1) {
              this.groups.splice(this.groups.length - 1, 0, {
                abbr: g.group,
                value: g.group,
              });
            }
          });

          return {
            ...item,
            groups: item.groups.map((g) => ({
              group: g.group,
              weight: g.weight * this.recipe.portions,
              weightUnit: g.weightUnit,
            })),

            active: !item.isBasic,
          };
        });
        this.loading = false;
      } catch (error) {
        console.error("Error loading shopping lists:", error);
      } finally {
      }
    },
  },
  activated() {
    if (this.$route.params.id) {
      useHead({
        title: "Ändra recept - NearbyStore",
        meta: [
          {
            hid: "description",
            name: "description",
            content: "Skapa recept på NearbyStore",
          },
        ],
        __key: "createRecipeHead",
      });
      this.fetchRecipe();
      this.create = false;
    } else {
      useHead({
        title: "Skapa recept - NearbyStore",
        meta: [
          {
            hid: "description",
            name: "description",
            content: "Skapa recept på NearbyStore",
          },
        ],
        __key: "createRecipeHead",
      });
      this.loading = false;
    }
  },
};
</script>
<style>
.big-text .v-field__input {
  font-size: 1.75rem;
  font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
}
</style>
