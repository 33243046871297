<template>
  <v-row class="ma-2">
    <v-col sm="12" class="p-0" align="center" justify="center" v-if="loading">
      <v-progress-circular color="primary" height="10" indeterminate />
    </v-col>
    <v-row justify="center" v-else class="pa-4">
      <v-col cols="12" md="10" lg="10" class="pa-0">
        <p class="bold-h1">Order</p>
        <v-card class="pa-4 mt-4" flat>
          <v-window disabled v-model="step">
            <v-window-item disabled :value="1">
              <Vue3Lottie
                animation-link="https://assets2.lottiefiles.com/packages/lf20_iawwfiag.json"
                :height="200"
              />
            </v-window-item>
            <v-window-item disabled :value="3">
              <div v-if="stop != null">
                <GMapMap
                  :center="center"
                  :zoom="18"
                  :options="options"
                  ref="mapRef"
                  :draggable="false"
                  style="height: 300px; margin-top: 0px; border-radius: 10px"
                >
                  <GMapMarker
                    v-for="mark in markers"
                    :position="mark"
                    :clickable="false"
                    :icon="{
                      url: mark.icon,
                      scaledSize: { width: 25, height: 25 },
                      labelOrigin: { x: 16, y: -10 },
                    }"
                  >
                  </GMapMarker>
                </GMapMap>
              </div>
              <Vue3Lottie
                v-else
                animation-link="https://assets2.lottiefiles.com/packages/lf20_iawwfiag.json"
                :height="200"
              />
            </v-window-item>
            <v-window-item disabled :value="4">
              <v-col align="center">
                <v-btn
                  v-if="
                    order.status == 'waiting' ||
                    (orderIsInTimeWindow(order) && order.status == 'delivered')
                  "
                  @click="dialog = true"
                  color="primary"
                  class="xl-button text-none"
                  block
                  flat
                  >{{
                    order.status == "waiting"
                      ? $t("pickup_order")
                      : $t("open_lockers")
                  }}</v-btn
                >
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="5"> </v-window-item>
          </v-window>
          <span class="bold-h2"
            ><span class="bold-h2" style="color: #003a70">
              Nr.
              {{ order.shortOrderNum.toString().padStart(4, "0") }} </span
            ><span class="bold-h2 ml-4"> {{ $t(order.status) }}</span>
          </span>

          <p
            class="pt-4 body-1"
            v-if="order.status == 'delivering' && stop != null"
          >
            {{
              (order.pickupPoint != null
                ? order.pickupPoint.name
                : order.pickupAddress.address) +
              " Estimerad ankomst: " +
              formatTime(stop.eta)
            }}
          </p>
          <p v-else-if="order.status == 'delivered'">
            {{
              (order.pickupPoint != null
                ? order.pickupPoint.name
                : order.pickupAddress.address) +
              " " +
              getDayLabel(order.deliveredAt, false) +
              " " +
              formatTime(order.deliveredAt)
            }}
          </p>
          <p class="pt-4 body-1" v-else>
            {{
              (order.pickupPoint != null
                ? order.pickupPoint.name
                : order.pickupAddress.address) +
              " " +
              getDayLabel(order.pickupTimeSlot.pickupAt, false) +
              " " +
              formatTime(order.pickupTimeSlot.pickupAt) +
              " - " +
              formatTime(order.pickupTimeSlot.pickupBefore)
            }}
          </p>
          <v-btn
            @click="goToWebsite(order.receiptUrl)"
            variant="text"
            class="text-none mt-4"
            color="primary"
            ><v-row align="center" justify="center"
              ><font-awesome-icon icon="fa-solid fa-receipt" />
              <p class="bold-h4 ml-2">{{ $t("receipt") }}</p></v-row
            ></v-btn
          >
        </v-card>
        <v-expansion-panels class="pt-4">
          <v-expansion-panel elevation="0">
            <v-expansion-panel-title class="bold-h3">{{
              $t("items_count", { count: order.totalItems })
            }}</v-expansion-panel-title>
            <v-expansion-panel-text style="background-color: #ececec">
              <v-card
                v-for="(product, index) in order.orderProductVariants"
                :key="product.id"
                class="pa-4 ma-2 mt-4"
                elevation="0"
              >
                <v-col class="pa-0">
                  <v-row>
                    <v-col
                      align="center"
                      justify="center"
                      cols="2"
                      sm="1"
                      class="pa-1"
                    >
                      <v-img
                        :src="product.thumbnail.url"
                        alt="Product Image"
                        class="hover"
                        height="100"
                        @click="handleProductClick(product)"
                      />
                    </v-col>
                    <v-col>
                      <v-col sm="12" class="pa-0">
                        <p
                          class="bold-h4 pt-0"
                          :style="
                            product.status == 'out' ||
                            product.status == 'replaced'
                              ? {
                                  textDecoration: 'line-through',
                                  color: 'grey',
                                }
                              : {}
                          "
                        >
                          {{ product.name }}
                        </p></v-col
                      >
                      <v-col
                        v-if="product.status == 'out'"
                        sm="2"
                        md="2"
                        cols="4"
                        align="center"
                        class="pa-0 mt-2"
                      >
                        <v-card elevation="0" color="error" class="pa-1"
                          ><p class="bold-h4 pb-2">{{ $t("out") }}</p></v-card
                        ></v-col
                      >
                      <v-col
                        v-if="
                          product.status == 'replaced' ||
                          product.status == 'partial_replaced'
                        "
                        cols="6"
                        sm="3"
                        md="2"
                        align="center"
                        class="pa-0 mt-2"
                      >
                        <v-card elevation="0" color="primary" class="pa-1"
                          ><p class="bold-h4 pb-2">
                            {{ $t("replaced") }}
                          </p></v-card
                        ></v-col
                      >

                      <v-col sm="12" class="pa-0">
                        <p
                          class="body-2"
                          :style="
                            product.status == 'out' ||
                            product.status == 'replaced'
                              ? {
                                  textDecoration: 'line-through',
                                  color: 'grey',
                                }
                              : {}
                          "
                        >
                          {{
                            product.manufacturer + " " + product.displayWeight
                          }}
                        </p></v-col
                      >
                    </v-col>
                    <v-col cols="4" sm="2" align="end">
                      <p class="bold-h4">
                        {{
                          product.price +
                          " /" +
                          (product.weightProduct
                            ? "kg"
                            : $t("piece_abbreviation"))
                        }}
                      </p>
                      <p
                        class="bold-h4"
                        :style="
                          product.status == 'out' ||
                          product.status == 'replaced'
                            ? { textDecoration: 'line-through', color: 'grey' }
                            : {}
                        "
                      >
                        {{
                          (order.status == "delivered" ||
                          order.status == "waiting"
                            ? product.pickedQuantity
                            : product.quantity) + " st"
                        }}
                      </p>
                      <p
                        class="bold-h4"
                        :style="
                          product.status == 'out'
                            ? { textDecoration: 'line-through', color: 'grey' }
                            : {}
                        "
                      >
                        {{
                          (order.status == "delivered" ||
                          order.status == "waiting"
                            ? product.totalPrice.toFixed(2)
                            : product.reservedPrice.toFixed(2)) + " kr"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-expansion-panels
                    class="pt-4"
                    v-if="product.replacementProducts.length > 0"
                  >
                    <v-expansion-panel elevation="0" class="pa-0">
                      <v-expansion-panel-title class="body-2">{{
                        $t("view_replacements")
                      }}</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-col
                          class="pa-0"
                          v-for="replacement in product.replacementProducts"
                        >
                          <v-row>
                            <v-col
                              align="center"
                              justify="center"
                              cols="2"
                              sm="1"
                              class="pa-1"
                            >
                              <v-img
                                :src="replacement.thumbnail.url"
                                alt="Product Image"
                                class="hover"
                                height="100"
                                @click="handleProductClick(replacement)"
                              />
                            </v-col>
                            <v-col>
                              <v-col sm="12" class="pa-0">
                                <p class="bold-h4 pt-0">
                                  {{ replacement.name }}
                                </p></v-col
                              >

                              <v-col sm="12" class="pa-0">
                                <p class="body-2">
                                  {{ replacement.manufacturer }}
                                </p></v-col
                              >
                            </v-col>
                            <v-col cols="4" sm="2" align="end">
                              <p class="bold-h4">
                                {{
                                  replacement.price.toFixed(2) +
                                  " /" +
                                  (replacement.weightProduct
                                    ? "kg"
                                    : $t("piece_abbreviation"))
                                }}
                              </p>
                              <p class="bold-h4">
                                {{ replacement.pickedQuantity + " st" }}
                              </p>
                              <p class="bold-h4">
                                {{ replacement.totalPrice.toFixed(2) + " kr" }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-expansion-panel-text></v-expansion-panel
                    ></v-expansion-panels
                  >
                </v-col>
              </v-card>
            </v-expansion-panel-text></v-expansion-panel
          ></v-expansion-panels
        >
        <v-col class="pa-0 pt-4">
          <v-card class="pa-4 sticky-card" elevation="0">
            <p class="bold-h2">{{ $t("overview") }}</p>

            <v-col>
              <v-row
                justify="space-between"
                class="bold-h4"
                style="color: #c94631"
              >
                <span>{{ $t("total_discount") }}</span>
                <span
                  >{{
                    (
                      order.price.productDiscount + order.price.cartDiscount
                    ).toFixed(2)
                  }}
                  kr</span
                >
              </v-row>
              <v-row justify="space-between" class="bold-h3 pt-1">
                <span>{{
                  $t("total") +
                  " (" +
                  $t("items_count", { count: order.totalItems }) +
                  ")"
                }}</span>
                <span
                  >{{
                    (order.status == "delivered"
                      ? order.price.total.toFixed(2)
                      : order.price.reserved.toFixed(2)) + " kr"
                  }}
                </span>
              </v-row>

              <v-col class="pt-8 pa-0" v-if="order.status == 'delivered'">
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._6.tax > 0"
                >
                  <span>{{ $t("including_vat_6_percent") }}</span>
                  <span>{{ order.taxes._6.tax.toFixed(2) + " kr" }} </span>
                </v-row>
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._12.tax > 0"
                >
                  <span>{{ $t("including_vat_12_percent") }}</span>
                  <span>{{ order.taxes._12.tax.toFixed(2) + " kr" }} </span>
                </v-row>
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._25.tax > 0"
                >
                  <span>{{ $t("including_vat_25_percent") }}</span>
                  <span>{{ order.taxes._25.tax.toFixed(2) + " kr" }} </span>
                </v-row>
              </v-col>
            </v-col>
          </v-card>
        </v-col>
      </v-col>
      <CollectOrderDialog
        :order="order"
        :visible="dialog"
        @close="closeCollectOrder"
      />
    </v-row>
  </v-row>
  <LoginModal
    :visible="loginModal"
    @done="loginDone"
    @close="loginDone"
  ></LoginModal>
</template>

<script>
import OrderAPI from "@/api/OrderAPI";
import { formatTime, getDayLabel } from "../utils.js";
import { Vue3Lottie } from "vue3-lottie";
import { mapState, mapActions } from "vuex";
import CollectOrderDialog from "@/components/CollectOrderDialog.vue";
import unselectedBit from "../assets/images/unselectedBit.png";
import selectedBit from "../assets/images/selectedBit.png";
import driverBit from "../assets/images/driverBit.png";
import { icon } from "@fortawesome/fontawesome-svg-core";
export default {
  name: "OrderPage",
  components: {
    CollectOrderDialog,
  },
  data() {
    return {
      orderId: "",
      loading: true,
      selectedBit: selectedBit,
      unselectedBit: unselectedBit,
      driverBit: driverBit,
      stop: null,
      orderTimer: null,
      dialog: false,
      loadingCollect: false,
      loginModal: false,
      markers: [],

      mapObject: null,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        draggable: true,
        fullscreenControl: false,
      },
      center: { lat: 58.407756, lng: 15.596989 },
    };
  },
  computed: {
    ...mapState(["currentLang", "categories", "order", "user"]),
    step() {
      if (
        this.order.status == "waiting" ||
        (new Date(this.order.pickupTimeSlot.pickupAt) < new Date() &&
          new Date(this.order.pickupTimeSlot.pickupBefore) > new Date())
      ) {
        return 4;
      } else if (this.order.status == "delivered") {
        return 5;
      } else if (this.order.status == "delivering") {
        return 3;
      } else {
        return 1;
      }
    },
  },

  deactivated() {
    clearInterval(this.orderTimer);
  },
  async activated() {
    await this.fetchOrder();
    if (!this.user || this.user._id != this.order.userId) {
      // Store the intended route and redirect to login
      this.loginModal = true;
      return;
    }
    this.loading = false;
    setTimeout(() => {
      this.updateDrivingRoute();
    }, 1000);
  },
  unmounted() {
    clearInterval(this.orderTimer);
  },

  watch: {
    currentLang(newVal) {
      this.fetchOrder();
    },
    order(newVal) {
      this.updateDrivingRoute();
    },
  },
  methods: {
    ...mapActions(["initializeCart", "getOrder"]),
    goToWebsite(url) {
      window.open(url, "_blank");
    },
    getDayLabel,
    formatTime,
    async loginDone() {
      this.loginModal = false;
      await this.fetchOrder();
      if (!this.user || this.user._id != this.order.userId) {
        // Store the intended route and redirect to login
        this.$router.push({
          name: "handla",
        });
      }
      this.loading = false;
      setTimeout(() => {
        this.updateDrivingRoute();
      }, 1000);
    },
    async collectOrder() {
      try {
        this.loadingCollect = true;
        const resp = await OrderAPI.collectOrder(this.order._id);
        this.order = resp.data;

        this.loadingCollect = false;
        this.dialog = false;
      } catch (error) {
        this.loadingCollect = false;
      }
    },
    async fetchOrder() {
      await this.getOrder(this.$route.params.id);
      this.stop =
        this.order.drivingRoute != null
          ? this.order.drivingRoute.upcomingStops.find((e) =>
              e.orders.includes(this.order._id)
            )
          : null;
    },
    orderIsInTimeWindow(order) {
      const now = new Date();
      const pickupBefore = new Date(order.pickupTimeSlot.pickupBefore);
      return now <= pickupBefore;
    },
    handleProductClick(product) {
      if (product.productId) {
        this.$router.push({
          name: "ProductDetail",
          params: { id: product.productId },
        });
      }
    },
    updateStep() {},
    async updateDrivingRoute() {
      if (this.stop != null) {
        this.markers = [];
        this.markers = this.order.drivingRoute.upcomingStops.map((item) => {
          return {
            id: item._id,
            lat: item.position.coordinates[1],
            lng: item.position.coordinates[0],
            icon: item._id == this.stop._id ? selectedBit : unselectedBit,
            rotation: 0,
          };
        });

        this.markers.push({
          id: this.order._id,
          lat: this.order.drivingRoute.driverPosition.coordinates[1],
          lng: this.order.drivingRoute.driverPosition.coordinates[0],
          icon: driverBit,
        });

        const bounds = new google.maps.LatLngBounds();

        // Extend bounds to include each marker
        this.markers.forEach((marker) => {
          bounds.extend({ lat: marker.lat, lng: marker.lng });
        });

        // Access the map instance and fit bounds
        if (this.$refs.mapRef != null) {
          const map = this.$refs.mapRef.$mapObject;
          if (map) {
            map.fitBounds(bounds);
          }
        }
      }
    },

    async closeCollectOrder() {
      await this.fetchOrder();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
/* Add any styling here if needed */
</style>
