<template>
  <svg :width="size.width" :height="size.height" viewBox="0 0 100 100">
    <polygon :points="points" :fill="fillColor" />
    <!-- Upper Text -->
    <text
      :x="multiple ? '50%' : isInteger ? '50%' : '38%'"
      :y="multiple ? '38%' : isInteger ? '47%' : '50%'"
      dominant-baseline="middle"
      text-anchor="middle"
      :fill="textColor"
      :font-size="multiple ? '24px' : '38px'"
      class="bold-1"
    >
      {{ upperText }}
    </text>
    <text
      :x="tripleDigit ? '75%' : '70%'"
      y="38%"
      dominant-baseline="middle"
      text-anchor="middle"
      :fill="textColor"
      :font-size="multiple ? '20px' : '20px'"
      class="bold-1"
    >
      {{ upperText2 }}
    </text>

    <!-- Lower Text -->
    <text
      :x="multiple ? (isInteger ? '50%' : '38%') : '70%'"
      :y="multiple ? (isInteger ? '70%' : '70%') : '70%'"
      dominant-baseline="middle"
      text-anchor="middle"
      :font-size="multiple ? '38px' : '20px'"
      class="bold-1"
      :fill="textColor"
    >
      {{ lowerText }}
    </text>
    <text
      :x="tripleDigit ? '78%' : '70%'"
      y="60%"
      dominant-baseline="middle"
      text-anchor="middle"
      :font-size="multiple ? '20px' : '20px'"
      class="bold-1"
      :fill="textColor"
    >
      {{ lowerText2 }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "StarWithText",
  props: {
    size: {
      type: Object,
      default: () => ({ width: 80, height: 80 }),
    },
    fillColor: {
      type: String,
      default: "#ffe400", // Amber color from Flutter example
    },

    promotion: { type: Object, default: () => ({}) },
    isWeight: { type: Boolean, default: false },
    textColor: {
      type: String,
      default: "#d2232a", // Default text color
    },
  },

  computed: {
    tripleDigit() {
      return this.promotion.discountValue >= 100;
    },
    multiple() {
      return this.promotion.minQuantity > 1;
    },
    isInteger() {
      return Number.isInteger(this.promotion.discountValue);
    },
    upperText() {
      return this.multiple
        ? this.promotion.minQuantity + " för"
        : Math.floor(this.promotion.discountValue) +
            (this.isInteger ? ":-" : "");
    },
    upperText2() {
      return this.multiple || this.isInteger
        ? ""
        : (
            (this.promotion.discountValue -
              Math.floor(this.promotion.discountValue)) *
            100
          ).toFixed(0);
    },
    lowerText() {
      return this.multiple
        ? Math.floor(this.promotion.discountValue) +
            (this.isInteger ? ":-" : "")
        : this.isWeight
        ? "/kg"
        : "/st";
    },
    lowerText2() {
      return !this.multiple || this.isInteger
        ? ""
        : (
            (this.promotion.discountValue -
              Math.floor(this.promotion.discountValue)) *
            100
          ).toFixed(0);
    },
    points() {
      const spikes = 16;
      const outerRadius = 50; // Half of the SVG viewBox size
      const innerRadius = outerRadius * 0.8;
      const centerX = 50; // Center of the SVG viewBox
      const centerY = 50;
      let points = [];

      for (let i = 0; i < spikes; i++) {
        const angle = (i * 2 * Math.PI) / spikes;
        const nextAngle = ((i + 1) * 2 * Math.PI) / spikes;

        const outerX = centerX + outerRadius * Math.cos(angle);
        const outerY = centerY + outerRadius * Math.sin(angle);

        const innerX =
          centerX + innerRadius * Math.cos((angle + nextAngle) / 2);
        const innerY =
          centerY + innerRadius * Math.sin((angle + nextAngle) / 2);

        points.push(`${outerX},${outerY}`);
        points.push(`${innerX},${innerY}`);
      }

      return points.join(" ");
    },
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
