<template>
  <v-container fluid
    ><v-col>
      <v-card elevation="0"
        ><v-col>
          <v-row class="pa-4">
            <h1 class="bold-h1">{{ $t("my_details") }}</h1>
            <v-row justify="end" class="pa-4">
              <v-btn
                v-if="edit"
                variant="flat"
                class="mr-3"
                icon="mdi-window-close"
                color="error"
                size="small"
                @click="cancelEdit"
              ></v-btn>
              <v-btn
                variant="flat"
                :icon="edit ? 'mdi-check' : 'mdi-pencil'"
                color="primary"
                size="small"
                @click="editDone"
              ></v-btn>
            </v-row>
          </v-row>

          <v-form v-model="form_create" ref="form_create" class="ma-0 pa-0">
            <v-col class="pa-0">
              <v-col>
                <v-text-field
                  v-model="user.email"
                  disabled
                  clearable
                  :rules="textFieldRules"
                  validate-on="submit"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  single-line
                  hide-details
                  placeholder="Email"
                  persistent-hint
              /></v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.firstName"
                      clearable
                      :rules="textFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      hide-details
                      :disabled="!edit"
                      :placeholder="$t('first_name')"
                      persistent-hint
                  /></v-col>
                  <v-col>
                    <v-text-field
                      v-model="user.lastName"
                      clearable
                      :rules="textFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      hide-details
                      :disabled="!edit"
                      :placeholder="$t('last_name')"
                      persistent-hint
                  /></v-col>
                </v-row>
              </v-col>
              <v-col class="pb-0">
                <v-phone-input
                  v-model="user.phone"
                  single-line
                  :label="$t('phone_number')"
                  :country-label="$t('country')"
                  defaultCountry="SE"
                  color="primary"
                  bg-color="surface"
                  :rules="textFieldRules"
                  validate-on="submit"
                  variant="outlined"
                  :disabled="!edit"
                  country-icon-mode="svg"
                />
              </v-col>
            </v-col>
          </v-form> </v-col
      ></v-card>
      <v-col>
        <h1 class="bold-h1">{{ $t("communication") }}</h1>
        <v-col
          class="pa-0"
          v-for="channel in notificationChannels"
          :key="channel"
        >
          <h1 class="bold-h2">{{ $t(channel) }}</h1>
          <v-col class="pa-0">
            <v-card
              v-for="n in user.notificationPreference.filter(
                (e) => e.notification.channel == channel
              )"
              :key="n._id"
              class="mt-4"
              elevation="0"
              :ripple="false"
              @click="notificationChanged(n)"
            >
              <v-row class="pa-4"
                ><v-col
                  ><p class="bold-h4">
                    {{ capitalizeFirstLetter(n.notification.name) }}
                  </p>
                  <p class="body-3">{{ n.notification.description }}</p></v-col
                >
                <v-switch
                  class="mr-4"
                  v-model="n.active"
                  hide-details
                  color="primary"
                  inset
                ></v-switch>
              </v-row>
            </v-card>
          </v-col>
        </v-col>
      </v-col>
      <v-col>
        <h1 class="bold-h1">{{ $t("my_addresses") }}</h1>
        <v-col v-if="user.shippingAddresses.length > 0" class="ma-0 pa-0">
          <v-card
            v-for="address in user.shippingAddresses"
            class="mt-2 ma-0 pa-0"
            elevation="0"
          >
            <v-row align="center" class="pa-6" justify="space-between">
              <v-col class="pa-0" align="start">
                <p class="bold-h4 pb-2">{{ address.address }}</p>
                <p class="body-2">
                  {{ address.postalCode + " " + address.city }}
                </p>
              </v-col>
              <v-col class="pa-0" align="end">
                <v-menu location="bottom" v-if="user">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-dots-vertical"
                      flat
                      variant="text"
                      @click.stop=""
                      v-bind="props"
                    ></v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="">
                      <v-list-item-title
                        class="bold-1 pb-2"
                        style="color: #003a70"
                        @click="changeAddress(address)"
                        >{{ $t("change") }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="removeAddress(address._id)">
                      <v-list-item-title
                        class="bold-1 pb-2"
                        style="color: #c94631"
                        >{{ $t("delete") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-else class="pa-0 pt-4">
          <p class="body-2">
            {{ $t("no_addresses") }}
          </p>
        </v-col>
        <v-btn
          class="mt-4 button text-none"
          color="primary"
          elevation="0"
          @click="toggleLocation"
          >{{ $t("add_address") }}</v-btn
        >
        <h1 class="bold-h1 mt-8">{{ $t("my_cards") }}</h1>
        <v-card v-for="card in cards" :key="card.id" class="mt-4" elevation="0">
          <v-row class="pa-8" justify="space-between" align="center">
            <font-awesome-icon
              :icon="getCardBrand(card.card.brand)"
              :size="!$vuetify.display.xs ? '4x' : '2x'"
            />

            <v-col class="ml-2 pa-0">
              <v-row class="pa" justify="space-between">
                <p class="bold-h2 mb-2 ml-4">
                  {{ capitalizeFirstLetter(card.card.brand) }}
                </p>
                <v-btn
                  color="error"
                  elevation="0"
                  class="button text-none mt-2 mr-2"
                  @click="detachUserCard(card)"
                  :loading="card.loading"
                  >{{ $t("remove") }}</v-btn
                ></v-row
              >

              <v-col class="pa-2 mt-1 body-3"
                ><p>{{ "**** **** **** " + card.card.last4 }}</p>
                <p>
                  {{
                    $t("expires") +
                    ": " +
                    (card.card.exp_month < 10 ? "0" : "") +
                    card.card.exp_month +
                    "/" +
                    card.card.exp_year
                  }}
                </p></v-col
              ></v-col
            >
          </v-row></v-card
        >

        <v-btn
          @click="addCard"
          v-if="!addingCard"
          color="primary"
          class="mt-4 button text-none"
          elevation="0"
          >{{ $t("add_card") }}</v-btn
        >
        <v-card v-if="addingCard" class="mt-4" elevation="0">
          <v-col>
            <p class="bold-h2">{{ $t("card_information") }}</p>
            <v-col> <div id="payment-element"></div></v-col>
            <v-row class="pa-4" justify="end">
              <v-btn
                class="mt-4 button text-none"
                @click="addingCard = false"
                color="error"
                elevation="0"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="mt-4 ml-4 button text-none"
                elevation="0"
                @click="handleSubmit"
                color="primary"
                :loading="loadingAddingCard"
                >{{ $t("add_card") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-col></v-container
  >
</template>
<script>
import Header from "@/components/Header.vue";
import { mapActions, mapState } from "vuex";
import PaymentAPI from "@/api/PaymentAPI";
import UserAPI from "@/api/UserAPI";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { capitalizeFirstLetter } from "../utils.js";
export default {
  name: "LoginPage",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      publishableKey: process.env.VITE_APP_STRIPE_KEY,
      edit: false,
      addingCard: false,
      paymentElement: {},
      cards: [],
      form_create: false,
      notificationChannels: [],
      paymentIntent: undefined,
      loadingAddingCard: false,
      emailRules: [
        (value) => {
          if (value) return true;

          return this.t("email_is_required");
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return this.t("email_must_be_valid");
        },
      ],
      textFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
      ],
    };
  },
  watch: {
    async currentLang(newVal) {
      const user = await UserAPI.getUser(this.user._id);
      if (user) {
        this.setUser(user);
      }
    },
  },
  async mounted() {
    this.stripe = await loadStripe(this.publishableKey, {
      betas: ["elements_customers_beta_1"],
      apiVersion: "2019-11-05",
    });
    const user = await UserAPI.getUser(this.user._id);
    if (user) {
      this.setUser(user);
    }

    this.notificationChannels = [
      ...new Set(
        this.user.notificationPreference.map((e) => e.notification.channel)
      ),
    ];
    this.getCards();
  },
  computed: {
    ...mapState(["currentLang", "user"]),
  },
  methods: {
    capitalizeFirstLetter,
    ...mapActions([
      "setUser",
      "updateUser",
      "moveCart",
      "toggleLocationModal",
      "removeUserAddress",
    ]),
    toggleLocation() {
      this.toggleLocationModal(4);
    },
    changeAddress(address) {
      const pickupTimeComponent = this.$root.locationSelection;
      if (pickupTimeComponent && pickupTimeComponent.startChangeAddress) {
        this.toggleLocationModal();
        pickupTimeComponent.startChangeAddress({ ...address });
      }
    },
    async removeAddress(addressId) {
      console.log("ADDRESS");
      console.log(this.cart.pickupAddress.address, address);
      if (
        this.cart.pickupAddress != null &&
        this.cart.pickupAddress.address == address.address &&
        this.cart.pickupAddress.postalCode == address.postalCode
      ) {
        await this.moveCart({});
      }
      await this.removeUserAddress(address._id);
      this.user.shippingAddresses = this.user.shippingAddresses.filter(
        (address) => address._id !== addressId
      );
    },
    async cancelEdit() {
      const user = await UserAPI.getUser(this.user._id);
      if (user) {
        this.setUser(user);
      }
      this.edit = false;
    },
    async editDone() {
      if (this.edit) {
        const { valid } = await this.$refs.form_create.validate();

        if (!valid) return;
        const body = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        };
        this.updateUser(body);
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    notificationChanged(n) {
      n.active = !n.active;
      const body = {
        notificationPreference: this.user.notificationPreference.map((e) => ({
          active: e.active,
          notification: e.notification._id,
        })),
      };
      this.updateUser(body);
    },
    getCardBrand(brand) {
      switch (brand) {
        case "visa":
          return "fa-brands fa-cc-visa";
        case "masterCard":
          return "fa-brands fa-cc-mastercard";
        case "americanExpress":
          return "fa-brands fa-cc-amex";
        case "discover":
          return "fa-brands fa-cc-discover";
        default:
          return "fa-solid fa-credit-card";
      }
    },
    async getCards() {
      this.cards = await UserAPI.getUserCards(this.user._id);
      this.cards = this.cards.map((e) => ({
        ...e,
        loading: false,
      }));
    },
    async detachUserCard(card) {
      try {
        card.loading = true;

        const resp = await UserAPI.detachUserCard(this.user._id, card.id);
        this.cards = resp;
        card.loading = false;
      } catch (error) {
        card.loading = false;
      }
    },
    async addCard() {
      this.paymentIntent = await PaymentAPI.createSetupIntent(this.user._id);
      this.addingCard = true;

      const loader = "auto";
      this.elements = await this.stripe.elements({
        loader,
        allow_redisplay: "always",
        clientSecret: this.paymentIntent.client_secret,
        locale: this.currentLang,
      });
      // Create and mount the payment element
      this.paymentElement = await this.elements.create("payment");
      this.paymentElement.mount("#payment-element");
    },
    async handleSubmit() {
      this.loadingAddingCard = true;
      const { error, setupIntent } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url:
            process.env.VITE_APP_FIREBASE_DEVELOPMENT == "production"
              ? "https://nearbystore.se/handla/profile"
              : "https://dev.nearbystore.se/handla/profile",
        },
        redirect: "if_required",
      });

      if (error) {
        console.error("Error confirming payment:", error);
      } else if (setupIntent.status == "succeeded") {
        await this.getCards();
        this.addingCard = false;
      }
      this.loadingAddingCard = false;
    },
  },
};
</script>
