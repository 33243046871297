import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store

/**
 * API for interacting with cart-related endpoints.
 */
const CartAPI = {
  /**
   * Creates a new cart with predefined and constant parameters, including the storeId and userId from Vuex store if available.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the new cart details.
   */
  fetchCart: (cartId) => {
    return axiosInstance.get("/cart/" + cartId);
  },

  /**
   * Creates a new cart with predefined and constant parameters, including the storeId and userId from Vuex store if available.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the new cart details.
   */
  createCart: () => {
    const storeId = store.state.storeId || ""; // Fetch storeId from Vuex store

    const userId = store.state.user?._id || ""; // Fetch userId from Vuex store if available
    const cart = store.state.cart;

    const cartDetails = {
      userId, // Bind userId if available, otherwise leave it blank
      status: "normal", // Fixed status
      storeId, // Retrieved from Vuex store
      homeDelivery: false,
      warehouse: true, // Always true
      productVariants: [], // Initially empty
    };

    if (store.state.cart != undefined) {
      if (store.state.cart.pickupPoint != undefined) {
        cartDetails.pickupPoint = store.state.cart.pickupPoint._id;
      }
      if (store.state.cart.pickupAddress != undefined) {
        const { _id, ...rest } = store.state.cart.pickupAddress;
        cartDetails.pickupAddress = rest;
        cartDetails.homeDelivery = true;
      }
    }

    return axiosInstance.post("/cart", cartDetails);
  },

  /**
   * Changes a product quantity in the cart.
   *
   * @param {string} cartId - The ID of the cart where the change is to be made.
   * @param {Object} productChange - Object containing the productId and quantity.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response after changing the product in the cart.
   */
  changeProductInCart: (cartId, productChange) => {
    return axiosInstance.post(`/cart/change/${cartId}`, productChange);
  },

  addProductsInCart: (cartId, products) => {
    return axiosInstance.post(`/cart/addProducts/${cartId}`, products);
  },

  addRecipeCart: (cartId, recipe) => {
    return axiosInstance.post(`/cart/addRecipe/${cartId}`, recipe);
  },
  updateRecipeCart: (cartId, body) => {
    return axiosInstance.post(`/cart/updateRecipe/${cartId}`, body);
  },
  removeRecipeCart: (cartId, recipeId) => {
    return axiosInstance.post(`/cart/removeRecipe/${cartId}`, {
      recipeId: recipeId,
    });
  },

  addShoppingListCart: (cartId, shoppingList) => {
    return axiosInstance.post(`/cart/addShoppingList/${cartId}`, shoppingList);
  },

  verifyPostalCode: (postalCode) => {
    const body = { postalCode: postalCode };
    return axiosInstance.post(`/warehouse/verifyPostalCode`, body);
  },

  updateShoppingListCart: (cartId, body) => {
    return axiosInstance.post(`/cart/updateShoppingList/${cartId}`, body);
  },
  removeShoppingListCart: (cartId, shoppingId) => {
    return axiosInstance.post(`/cart/removeShoppingList/${cartId}`, {
      shoppingId: shoppingId,
    });
  },

  updateReplacementPreferences: (cartId, body) => {
    return axiosInstance.patch(
      `/cart/updateReplacementPreferences/${cartId}`,
      body
    );
  },
  /**
   * Cleans a cart.
   *
   * @param {string} cartId - The ID of the cart that should be empty.
   * @returns {Promise<AxiosResponse>} The empty cart.
   */
  emptyCart: (cartId) => {
    return axiosInstance.post(`/cart/clean/${cartId}`);
  },

  reserveCart: () => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      throw new Error("Cart ID not found");
    }
    return axiosInstance.post(`/cart/reserve/${cartId}`);
  },
  unReserveCart: () => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      throw new Error("Cart ID not found");
    }
    return axiosInstance.post(`/cart/unReserve/${cartId}`);
  },
  /**
   * Checks out the cart, adding the latest available pickup time.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response after checking out the cart.
   */
  checkoutCart: () => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      throw new Error("Cart ID not found");
    }
    return axiosInstance.post(`/cart/checkout/${cartId}`);
  },

  changePickupTime: (time) => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      throw new Error("Cart ID not found");
    }
    const body = {
      pickupTimeId: time._id,
    };
    if (store.state.cart.pickupAddress != null) {
      body.postalCode = store.state.cart.pickupAddress.postalCode;
    }
    return axiosInstance.post(`/cart/changePickupTime/${cartId}`, body);
  },

  /**
   * Updates the user ID in the cart.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response after updating the cart.
   */
  moveCart: (updateParams) => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      return;
    }

    return axiosInstance.post(`/cart/move/${cartId}`, updateParams);
  },
  /**
   * Updates the user ID in the cart.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response after updating the cart.
   */
  updateCart: (updateParams) => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store

    if (!cartId) {
      throw new Error("Cart ID not found");
    }

    return axiosInstance.patch(`/cart/${cartId}`, updateParams);
  },

  removePromotion: () => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store
    if (!cartId) {
      throw new Error("Cart ID not found");
    }
    return axiosInstance.post(`/cart/removePromotion/${cartId}`);
  },
  validatePromoCode: (body) => {
    const cartId = store.state.cart._id; // Fetch cartId from Vuex store

    if (!cartId) {
      throw new Error("Cart ID not found");
    }

    return axiosInstance.post(`/cart/applyPromotion/${cartId}`, body);
  },
};

export default CartAPI;
