<template>
  <v-card
    @click="edit ? $refs.inputUpload.click() : null"
    elevation="0"
    style="border: 1px solid #808080"
  >
    <v-img :aspect-ratio="ratio" cover v-if="propImage" :src="propImage.url" />
    <v-img v-else :src="logo" :aspect-ratio="ratio" cover />
    <input
      v-show="false"
      ref="inputUpload"
      type="file"
      accept=".jpg, .jpeg, .png"
      @change="handleFileUpload"
    />
  </v-card>
</template>
<script>
import RecipeAPI from "@/api/RecipeAPI";
import logo from "../assets/images/template-banner.png";
export default {
  data() {
    return {
      logo: logo,
      file: null,
      maxImageSizeKB: 10000,
      imageUploadData: {},
      propImage: null,
    };
  },
  props: ["image", "ratio", "edit", "type"],
  computed: {},
  activated() {},
  mounted() {
    if (this.image != undefined) {
      this.propImage = this.image;
    }
  },
  methods: {
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    async handleFileUpload(ev) {
      this.file = ev.target.files[0];
      const maxSize = 4000 * 1024; // Convert KB to bytes

      if (this.file.size > maxSize) {
        // File size exceeds the limit
        this.$store.dispatch("vtoast/showToast", {
          message: "File size exceeds the limit of 500KB.",
          color: "error",
        });

        this.$refs.inputUpload.value = ""; // Clear the file input
        return;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      reader.onload = (e) => {
        this.imageUploadData.FileName = this.file.name;
        this.imageUploadData.data = e.target.result;
        this.imageUploadData.Blob = this.arrayBufferToBase64(e.target.result);
      };

      this.propImage = {};
      this.propImage.url = URL.createObjectURL(this.file);

      const image = new Image();
      const imageDimensions = await new Promise((resolve) => {
        image.onload = () => {
          const dimensions = {
            height: image.height,
            width: image.width,
          };
          resolve(dimensions);
        };
        image.src = this.propImage.url;
      });
    },
    hasImage() {
      return this.imageUploadData.Blob != undefined;
    },
    setPropImage() {
      this.propImage = null;
    },
    async uploadImage(data, create, id) {
      if (this.imageUploadData.Blob != undefined) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("data", JSON.stringify(data));
        // Check if the file is correctly appended
        return formData;
      }
    },
  },
};
</script>
